import React, { useState, useEffect } from "react";
import { BASE_URL } from '../utils/config';
import { useAuth, UserButton } from '@clerk/clerk-react';
import { AssistantMessageBubble, UserMessageBubble, SystemMessageBubble, ErrorMessageBubble } from '../components/MessageBubble';

const frame = {
    backgroundColor: 'white',
    color: 'white'
}

const AdminDashboardPage = () => {
    const { getToken, isLoaded, isSignedIn } = useAuth();

    const [selectedChat, setSelectedChat] = useState(null);
    const [chats, setChats] = useState([]);

    const [chatHistory, setChatHistory] = useState([]);

    useEffect(() => {

        const getChats = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/admin-dashboard`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${await getToken()}`,
                    }
                });
                const data = await response.json();
                console.log(data);
                setChats(data.data);
            } catch (error) {
                console.log(error);
            }

        }

        getChats();

    }, []);


    const loadChatHistory = async (chatId) => {
        setSelectedChat(chatId);
        setChatHistory([]);

        const url = `${BASE_URL}/api/admin-dashboard-load-history?chat_id=${chatId}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await getToken()}`,
                }
            });
            const data = await response.json();
            console.log('data: ', data);
            setChatHistory(data.data.history);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div style={{position: 'absolute', top: '0', right: '0', margin: '0.4%'}}>
                <UserButton />
            </div>
        

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Admin Dashboard</h1>
                        <a href="/chat">Go to chat</a>
                        <hr />
                    </div>

                    <div className="col-md-3">
                        {chats.map((chat, index) => (  
                            <div className={selectedChat === chat.id ? "card bg-info" : "card"} key={index} style={frame}>
                                <div className="card-body" onClick={() => loadChatHistory(chat.id)}>
                                    <p>{chat.id}</p>
                                    <p>{chat.convo_id}</p>
                                    <p>{chat.created_at}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="col-md-8">
                        { chatHistory.map((chat, index) => {
                            if (chat.role == 'assistant') {
                                return <AssistantMessageBubble key={index} message={chat.content} />
                            } else if (chat.role == 'user') {
                                return <UserMessageBubble key={index} message={chat.content} />
                            }
                        })}
                    </div>

                </div>

            </div>
        
        </>
    )

}

export default AdminDashboardPage;