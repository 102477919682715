import React, { useState, useEffect, useRef } from 'react';
import BASE_URL from '../utils/config';
import { SignOutButton, SignInButton, SignedIn, SignedOut, SignIn, SignUp } from "@clerk/clerk-react"

const SignupPage = () => {


    return (
            <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>       
                    <SignUp 
                        redirectUrl={'/chat'}
                        signInUrl='/login'
                    />
            </div>            
    )

}


export default SignupPage;