

export const DOC_TYPES = {
    REACT_DOCS : 'reactjs',
    DOCUMENTATION : 'documentation',
    GDOCS : 'gdocs',
    GFORMS: 'gforms',
    GSHEETS: 'gsheets',
    GSLIDES: 'gslides',
    GMAIL: 'gmail',
    WIKIPEDIA : 'wikipedia',
    EMPLOYEE : 'employee',
    SLACK: 'slack',
    STACKOVERFLOW : 'stackoverflow'
}

export const BASE_URL = () => {
    const dev = 'http://localhost:8000';
    const prod = 'https://likosapp.herokuapp.com';
    return process.env.NODE_ENV === 'prod' ? prod : dev;
}

export const MESSAGE_ROLE_TYPE = {
    USER: 'user',
    ASSISTANT: 'assistant',
    SYSTEM: 'system',
    ERROR: 'error',
}


export const ROLE_NAMES = {
    USER: 'You',
    ASSISTANT: 'Fin+ Assistant',
    SYSTEM: 'Fin+',
    ERROR: 'Error'
}