import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Updated import for React Router v6

/**
 * A component that redirects to the /chat route when rendered.
 */
const RedirectToChat = () => {
    const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the /chat route
    navigate('/chat');
  }, [navigate]); // Empty dependency array means this effect runs once on mount

  return null; // This component doesn't render anything
};

export default RedirectToChat;
