
const getBaseUrl = () => {
    const dev = 'http://127.0.0.1:7000';
    const prod_old = 'https://bj8dokbpsl.execute-api.us-east-1.amazonaws.com';
    const prod_old_2 = 'https://o7611qzaed.execute-api.us-east-2.amazonaws.com';
    const prod = 'https://api.finplus.ai';
    console.log('NODE_ENV: ', process.env.NODE_ENV);
    return process.env.NODE_ENV === 'development' ? dev : prod;
}

export const BASE_URL = getBaseUrl();