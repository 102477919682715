import logo from './logo.svg';
import './App.css';
import Nav from './components/Nav';
import { Routes, Route } from 'react-router-dom';

import Chat from './pages/ChatPage';
import NewChat from './pages/NewChatPage';
import LoginPage from './pages/LoginPage';
import Signup from './pages/SignupPage';
import { RedirectToSignIn,
  SignedIn,
  SignedOut,  
  RedirectToUserProfile,
} from '@clerk/clerk-react';
import { ClerkProvider } from '@clerk/clerk-react'
import UserProfilePage from './pages/UserProfilePage';
import { UserProfile } from "@clerk/clerk-react";
import DarkChatPage from './pages/DarkChatPage';
import AdminDashboardPage from './pages/AdminDashboard';
import RedirectToChat from './components/RedirectToChat';
import MobileChatPage from './pages/MobileChatPage';

const PUBLISHABLE_KEY = process.env.NODE_ENV === 'development' ? 'pk_test_ZXF1YWwtZ2F0b3ItMTkuY2xlcmsuYWNjb3VudHMuZGV2JA' : 'pk_live_Y2xlcmsuZmlucGx1cy5haSQ'

function App() {
  return (
    <ClerkProvider 
      publishableKey={PUBLISHABLE_KEY}
      appearance={
        {
            "baseTheme": "dark",
            "variables": {
             "colorBackground": "rgb(21, 21, 21)",
             "colorInputBackground": "rgb(21, 21, 21)",
             "colorAlphaShade": "#FFA500",
             "colorText": "#FFA500",
             "colorInputText": "#FFA500",
             "colorPrimary": "#FFA500",
             "colorSuccess": "green",
             "colorDanger": "red",
             "colorWarning": "yellow",
            },
            "elements": {
             "logoImage": {
              "filter": "brightness(0) invert(0)"
             },
             "socialButtonsProviderIcon__github": {
              "filter": "brightness(0) invert(1)"
             },
             "footer": {
              "& + div": {
               "background": "#151515"
              }
             }
            }
           }
    }
      >
      <Routes>
        <Route path="/"
            element={
              <>
                <SignedOut>
                  <LoginPage />
                </SignedOut>
                <SignedIn>
                  <RedirectToChat />
                </SignedIn>  
              </>
            } 
          />
        <Route 
          path="/chat"
          element={<>
            <SignedIn>
              <MobileChatPage />
            </SignedIn>
            <SignedOut>
              <RedirectToSignIn />
            </SignedOut>
            </>
          } 
        />

        <Route
          path="/admin-dashboard"
          element={<>
            <SignedIn>
              <AdminDashboardPage />
            </SignedIn>            
            <SignedOut>
              <RedirectToSignIn />
            </SignedOut>
          </>
          }
        >
        </Route>
                 
        <Route path="/signup" element={<Signup />} />


      
      </Routes>
      </ClerkProvider>
  );
}








function OldApp() {
  return (
    <>
    <Routes>
      <Route path="*" element={<h1>404 Not Found</h1>} />
    </Routes>
    </>    
  );
}

export default App;
