import React, { useState, useEffect, useRef } from 'react';
import { BASE_URL } from '../utils/config';
import ChatPageHeaderBox from '../components/ChatPageHeaderBox';
import Form from 'react-bootstrap/Form';
import { MESSAGE_ROLE_TYPE } from '../utils/enums';
import { AssistantMessageBubble, UserMessageBubble, SystemMessageBubble, ErrorMessageBubble } from '../components/MessageBubble';
import { tickerOptions } from '../utils/TickerOptions';
import Select from 'react-select';
import { useAuth, UserButton } from '@clerk/clerk-react';

import { SidebarItem } from "../components/SidebarItem";
import { IconlyBulkPlus1 } from "../icons/IconlyBulkPlus1";
import { v4 as uuidv4 } from 'uuid';







const designComponentInstanceNode = {
    marginTop: '-2px !important',
}



const iconlyBulkDiscoveryStyle = {

}

export const IconlyBulkDiscovery = ({ color = "#EF6820", opacity = "0.1"}) => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'middle' }}
        >
            <path opacity="0.1" d="M21.9999 11.9999C21.9999 17.5229 17.5229 21.9999 11.9999 21.9999C6.47688 21.9999 1.99988 17.5229 1.99988 11.9999C1.99988 6.47791 6.47688 1.99991 11.9999 1.99991C17.5229 1.99991 21.9999 6.47791 21.9999 11.9999Z" fill="#EF6820"/>
            <path d="M15.8597 8.7049L14.2397 13.8249C14.1797 14.0349 14.0097 14.2049 13.7997 14.2659L8.69972 15.8649C8.35972 15.9759 8.02972 15.6449 8.13972 15.3049L9.73972 10.1749C9.79972 9.9649 9.96972 9.8049 10.1797 9.7349L15.2997 8.1349C15.6497 8.0249 15.9697 8.3549 15.8597 8.7049Z" fill="#EF6820"/>
        </svg>

    );
  };

const ValueBoxComponent = ({text, text2}) => {
    return (                            
    <div className="valueBox valueBoxInstance">

        <div className="valueBoxFrame">
            <p className="valueBoxIntuitiveQuery">{text}</p>
            <p className="valueBoxAskSimpleQuestions display-desktop">{text2}</p>
        </div>
    </div>

    )
}


const IntroBoxComponent = () => {
    const iconlyBulkActivity = "https://cdn.animaapp.com/projects/64cdaabdc3058159a539613b/releases/65a17aae9aa2a391615fe641/img/iconly-bulk-activity-1.svg";
    return (
        <>
            <div className="introBoxInstance">
                <div className="introBox">
                    <div className="introBoxDiv">
                        <div className="iconWrapper">
                            <img className="iconImg" src={iconlyBulkActivity} />
                        </div>
                        <div className="introBoxtextWrapper">Why use Fin+</div>

                        <div className="frame2">
                            <div className="value-box-container">   
                                <ValueBoxComponent
                                    text={<span className="centered-text">Intuitive Querying in Plain English</span>}
                                    text2={"Ask simple questions and receive clear, accurate answers"}
                                />
                             </div>
                             <div className="value-box-container">
                                <ValueBoxComponent
                                    text={<span className="centered-text">Comprehensive and Timely Data</span>}
                                    text2={"Access up-to-date information on financial metrics and company performance."}
                                />
                            </div>
                            <div className="value-box-container">
                                <ValueBoxComponent
                                    text={<span className="centered-text">In-Depth Company Analysis</span>} 
                                    text2={"Detailed insights into management teams and strategic company details."}
                                />
                            </div>
  

                            <div className="value-box-container valueBoxDivWrapper">
                                <div className="valueBoxP text-center">
                                    The content and tools on this website, are not meant to be interpreted as financial advice.
                                    <br /> 
                                    The operators of this service bear no liability for its use or interpretation.
                                </div>                                
                            </div>
                        </div>

                </div>
            </div>
        </div>

        <div className="">

        </div>
    </>
    )
}


export const MobileChatPage = () => {


    const { getToken, isLoaded, isSignedIn } = useAuth();
    const testMessages = [
        //{'role': MESSAGE_ROLE_TYPE.WELCOME_MESSAGE, 'message': 'Welcome to the chatbot! How can I help you today?'}
    ];
    const [promptMessage, setPromptMessage] = useState('');
    const [messages, setMessages] = useState(testMessages);    
    const [promptArea, setPromptArea] = useState('');
    const promptAreaRef = useRef(null);
    const [chatSessionId, setChatSessionId] = useState(uuidv4());

    const reset = () => {
        setChatSessionId(uuidv4());
        setMessages([]);
    }    

    const scrollToDivRef = useRef(null);
    useEffect(() => {
        scrollToDivRef.current.scrollIntoView({ behavior: 'instant' });
    }, [messages]);  
    
    useEffect(() => {

        const fetchPromptAnswer = async (prompt) => {
            const url = `${BASE_URL}/api/new-prompt-answer`;            
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${await getToken()}`,
                },
                    body: JSON.stringify({
                        'prompt': prompt,
                        'chat_session_id': chatSessionId,
                    }),
                });
                //checkIfLoggedOut(response);
                if (!response.body) return; 
                if (response.status === 400) {
                    // return 400 error
                    const data = await response.json();
                    const message = data.message;
                    setMessages([...messages, {'role': MESSAGE_ROLE_TYPE.ERROR, 'message': message}]);
                    return;
                }
                if (response.status === 500) {
                    setMessages([...messages, {'role': MESSAGE_ROLE_TYPE.ERROR, 'message': 'There was an error in the system. Please contact support.'}]);
                    return;
                }
                const data = await response.json();
                const answer = data.answer;
                const questionid = data.questionid;
                const sources = data.sources;
                setMessages([...messages, {'role': MESSAGE_ROLE_TYPE.ASSISTANT, 'message': answer, 'questionid': questionid, 'sources': sources}]);
                scrollToDivRef.current.scrollIntoView({ behavior: 'smooth' });

            } catch (error) {
                console.log('Error: ', error);
            }
        }

        if (promptMessage) {
            fetchPromptAnswer(promptMessage);
            setPromptMessage('');
        }
    }, [promptMessage]);
    

    const submitMessage = () => {
        if (!promptArea) return;
        console.log('Submit message: ', promptArea);
        const promptMessage = promptArea;
        setMessages([...messages, {'role': MESSAGE_ROLE_TYPE.USER, 'message': promptArea}]);
        scrollToDivRef.current.scrollIntoView({ behavior: 'smooth' });
        setPromptArea('');
        setPromptMessage(promptMessage);
    }    

    const submitMessageInline = (micMessage) => { 
        if (!micMessage) return;
        console.log('Submit message: ', micMessage);
        const promptMessage = micMessage;
        setMessages([...messages, {'role': MESSAGE_ROLE_TYPE.USER, 'message': promptMessage}]);
        scrollToDivRef.current.scrollIntoView({ behavior: 'smooth' });
        setPromptArea('');
        setPromptMessage(promptMessage);
    }

    const submitQuickQuestions = (prompt) => {
        setMessages([...messages, {'role': MESSAGE_ROLE_TYPE.USER, 'message': prompt}]);
        //scrollToDivRef.current.scrollIntoView({ behavior: 'smooth' });
        setPromptArea('');
        setPromptMessage(prompt);        
    }

    const PopularQuestionBlock = ({text, divClassName, rawText}) => {
        return (
                <div className="questionBlockInstance designComponentInstanceNode" onClick={() => submitQuickQuestions(rawText)}>
                    <p className="questionBlockTextWrapper">                    
                        {text}                    
                    </p>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.2" d="M11.9999 2.94096C17.5149 2.94096 21.9999 7.42696 21.9999 12.941C21.9999 18.455 17.5149 22.941 11.9999 22.941C6.48589 22.941 1.99989 18.455 1.99989 12.941C1.99989 7.42696 6.48589 2.94096 11.9999 2.94096Z" fill="#EF6820"/>
                        <path d="M10.5575 8.72026C10.7485 8.72026 10.9405 8.79326 11.0865 8.93926L14.5735 12.4093C14.7145 12.5503 14.7935 12.7413 14.7935 12.9413C14.7935 13.1403 14.7145 13.3313 14.5735 13.4723L11.0865 16.9443C10.7935 17.2363 10.3195 17.2363 10.0265 16.9423C9.7345 16.6483 9.7355 16.1733 10.0285 15.8813L12.9815 12.9413L10.0285 10.0013C9.7355 9.70926 9.7345 9.23526 10.0265 8.94126C10.1725 8.79326 10.3655 8.72026 10.5575 8.72026Z" fill="#EF6820"/>
                    </svg>
                </div>
        );
    }

    const PopularQuestionsGroup = () => {
        return (
            <div className="frame4PopularQuestion">
                <div className="popularQuestion">POPULAR QUESTIONS</div>
                <div className="frame5PopularQuestion display-mobile">
                    <PopularQuestionBlock
                        text={"What was Tesla's revenue in Q1 2023?"}
                        rawText={"What was Tesla's revenue in Q1 2023?"}
                    />      
                </div>
                <div className="frame5PopularQuestion display-mobile">
                    <PopularQuestionBlock
                        text={
                            <>
                            What is Virgin Galactic's management <br />
                            team?
                            </>
                        }
                        rawText={"What is Virgin Galactic's management team?"}
                        divClassName={designComponentInstanceNode}
                    />                          
                </div>
                <div className="frame5PopularQuestion display-desktop">
                    <PopularQuestionBlock
                        text={"What was Tesla's revenue in Q1 2023?"}
                        rawText={"What was Tesla's revenue in Q1 2023?"}
                    />                            
                    <PopularQuestionBlock
                        text={
                            <>
                            What is Tesla's revenue by geography in <br />
                            Q2 2023?
                            </>
                        }
                        rawText={"What is Tesla's revenue by geography in Q2 2023?"}
                        divClassName={designComponentInstanceNode}
                    /> 
                </div>
                <div className="frame5PopularQuestion display-desktop">
                    <PopularQuestionBlock
                        text={
                            <>
                            What is Virgin Galactic's management <br />
                            team?
                            </>
                        }
                        rawText={"What is Virgin Galactic's management team?"}
                        divClassName={designComponentInstanceNode}
                    />                                                            
                    <PopularQuestionBlock
                        text={
                            <>
                            How many cars did Tesla ship in the last <br />
                            quarter?
                            </>
                        }
                        rawText={"How many cars did Tesla ship in the last quarter?"}
                        divClassName={designComponentInstanceNode}
                    />                                
            
                </div>
            </div>
        )
    }    

    const PromptBox2 = () => {
        return (
            <div className="frame3">
                {messages.length == 0 && <PopularQuestionsGroup />}
                <textarea 
                    ref={promptAreaRef}
                    value={promptArea}
                    onChange={(e) => setPromptArea(e.currentTarget.value)}
                    className="frame6"
                    placeholder="Ask a question"
                    rows="1"
                    disabled={false}
                    onKeyDown={(e) => {
                        const keyCode = e.keyCode;
                        if (keyCode === 13) {
                            e.preventDefault();
                            submitMessage();
                        }
                    }}                                        
                >  
                        <div className="frame6TextWrapper">Ask a question</div>                                      
                </textarea>
                
                    
                
            </div>
        )
    }



    const [isOpen, setIsOpen] = useState(false);

    const HamburgerMenu = () => {

        useEffect(() => {
            // This function will be called when clicking anywhere in the document
            const handleDocumentClick = (event) => {
              // If the click is inside the menu, don't do anything
              if (event.target.closest('.hamburger-menu, .sidebar')) {
                return;
              }
        
              // If the click was outside the menu, close the menu
              setIsOpen(false);
            };
        
            // Set up the event listener
            if (isOpen) {
              // Delay the addition of the event listener to let the current event finish
              const timer = setTimeout(() => {
                document.addEventListener('click', handleDocumentClick);
              }, 0);
        
              // Return a cleanup function that clears the timeout and removes the event listener
              return () => {
                clearTimeout(timer);
                document.removeEventListener('click', handleDocumentClick);
              };
            }
        }, [isOpen]); // Effect runs only when the value of isOpen changes

        const toggleMenu = () => {
          setIsOpen(!isOpen);
        };
      
        return (
          <div className="hamburger-menu">
            <nav className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className="hamburger-icon" onClick={toggleMenu}>
                    X
                </div>
              <ul style={{paddingTop: '8%', paddingLeft: '5%'}}>
                <li className="textWrapper2">
                    <span onClick={() => {
                        setIsOpen(false);
                        reset()
                    }}>
                        New Chat&nbsp;&nbsp;&nbsp;
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.8">
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.75 16.1543C11.336 16.1543 11 15.8183 11 15.4043V8.0773C11 7.6633 11.336 7.3273 11.75 7.3273C12.164 7.3273 12.5 7.6633 12.5 8.0773V15.4043C12.5 15.8183 12.164 16.1543 11.75 16.1543Z" fill="white" fillOpacity="0.9" stroke="white" strokeWidth="1"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.4165 12.4902H8.0835C7.6685 12.4902 7.3335 12.1542 7.3335 11.7402C7.3335 11.3262 7.6685 10.9902 8.0835 10.9902H15.4165C15.8305 10.9902 16.1665 11.3262 16.1665 11.7402C16.1665 12.1542 15.8305 12.4902 15.4165 12.4902Z" fill="white" fillOpacity="0.9" stroke="white" strokeWidth="1"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.064 2.5C4.292 2.5 2.5 4.397 2.5 7.335V16.165C2.5 19.103 4.292 21 7.064 21H16.436C19.209 21 21 19.103 21 16.165V7.335C21 4.397 19.209 2.5 16.436 2.5H7.064ZM16.436 22.5H7.064C3.437 22.5 1 19.954 1 16.165V7.335C1 3.546 3.437 1 7.064 1H16.436C20.063 1 22.5 3.546 22.5 7.335V16.165C22.5 19.954 20.063 22.5 16.436 22.5Z" fill="white" fillOpacity="0.9" stroke="white" strokeWidth="1"/>
                            </g>
                        </svg>
                    </span>
                </li>
              </ul>
            </nav>
          </div>
        );
      };
    
    
    return (
        <>
            <div style={{position: 'absolute', top: '0', right: '0', margin: '3%', zIndex: '100'}}>
                <UserButton />
            </div>
            <div className="display-mobile" style={{position: 'absolute', top: '', left: '0', margin: '3%', zIndex: '1000'}}>   
                <span onClick={() => setIsOpen(true)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.8">
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.75 16.1543C11.336 16.1543 11 15.8183 11 15.4043V8.0773C11 7.6633 11.336 7.3273 11.75 7.3273C12.164 7.3273 12.5 7.6633 12.5 8.0773V15.4043C12.5 15.8183 12.164 16.1543 11.75 16.1543Z" fill="white" fillOpacity="0.9" stroke="white" strokeWidth="1"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.4165 12.4902H8.0835C7.6685 12.4902 7.3335 12.1542 7.3335 11.7402C7.3335 11.3262 7.6685 10.9902 8.0835 10.9902H15.4165C15.8305 10.9902 16.1665 11.3262 16.1665 11.7402C16.1665 12.1542 15.8305 12.4902 15.4165 12.4902Z" fill="white" fillOpacity="0.9" stroke="white" strokeWidth="1"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.064 2.5C4.292 2.5 2.5 4.397 2.5 7.335V16.165C2.5 19.103 4.292 21 7.064 21H16.436C19.209 21 21 19.103 21 16.165V7.335C21 4.397 19.209 2.5 16.436 2.5H7.064ZM16.436 22.5H7.064C3.437 22.5 1 19.954 1 16.165V7.335C1 3.546 3.437 1 7.064 1H16.436C20.063 1 22.5 3.546 22.5 7.335V16.165C22.5 19.954 20.063 22.5 16.436 22.5Z" fill="white" fillOpacity="0.9" stroke="white" strokeWidth="1"/>
                            </g>
                        </svg>
                </span>
            </div>
            <HamburgerMenu />
            <div className="row h-100 ">

                <div className="col-0 col-sm-0 col-md-3 col-lg-2 col-xl-2 h-100 display-desktop">
                    <div className="frame-4 frame4 pt-3" onClick={() => reset()}>
                        <img
                            className="img mobile-hidden"
                            alt="Iconly bulk activity"
                            src="https://cdn.animaapp.com/projects/64cdaabdc3058159a539613b/releases/65a17aae9aa2a391615fe641/img/iconly-bulk-activity-2.svg"
                        />
                    
                        <div className="text-wrapper-2 textWrapper2 mobile-hidden" >New Chat&nbsp;&nbsp;&nbsp;</div>
                    
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.8">
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.75 16.1543C11.336 16.1543 11 15.8183 11 15.4043V8.0773C11 7.6633 11.336 7.3273 11.75 7.3273C12.164 7.3273 12.5 7.6633 12.5 8.0773V15.4043C12.5 15.8183 12.164 16.1543 11.75 16.1543Z" fill="white" fillOpacity="0.9" stroke="white" strokeWidth="1"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.4165 12.4902H8.0835C7.6685 12.4902 7.3335 12.1542 7.3335 11.7402C7.3335 11.3262 7.6685 10.9902 8.0835 10.9902H15.4165C15.8305 10.9902 16.1665 11.3262 16.1665 11.7402C16.1665 12.1542 15.8305 12.4902 15.4165 12.4902Z" fill="white" fillOpacity="0.9" stroke="white" strokeWidth="1"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.064 2.5C4.292 2.5 2.5 4.397 2.5 7.335V16.165C2.5 19.103 4.292 21 7.064 21H16.436C19.209 21 21 19.103 21 16.165V7.335C21 4.397 19.209 2.5 16.436 2.5H7.064ZM16.436 22.5H7.064C3.437 22.5 1 19.954 1 16.165V7.335C1 3.546 3.437 1 7.064 1H16.436C20.063 1 22.5 3.546 22.5 7.335V16.165C22.5 19.954 20.063 22.5 16.436 22.5Z" fill="white" fillOpacity="0.9" stroke="white" strokeWidth="1"/>
                            </g>
                        </svg>


                        {/***
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.8">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M11.75 16.1543C11.336 16.1543 11 15.8183 11 15.4043V8.0773C11 7.6633 11.336 7.3273 11.75 7.3273C12.164 7.3273 12.5 7.6633 12.5 8.0773V15.4043C12.5 15.8183 12.164 16.1543 11.75 16.1543Z" fill="white" fillOpacity="0.9"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.4165 12.4902H8.0835C7.6685 12.4902 7.3335 12.1542 7.3335 11.7402C7.3335 11.3262 7.6685 10.9902 8.0835 10.9902H15.4165C15.8305 10.9902 16.1665 11.3262 16.1665 11.7402C16.1665 12.1542 15.8305 12.4902 15.4165 12.4902Z" fill="white" fillOpacity="0.9"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.064 2.5C4.292 2.5 2.5 4.397 2.5 7.335V16.165C2.5 19.103 4.292 21 7.064 21H16.436C19.209 21 21 19.103 21 16.165V7.335C21 4.397 19.209 2.5 16.436 2.5H7.064ZM16.436 22.5H7.064C3.437 22.5 1 19.954 1 16.165V7.335C1 3.546 3.437 1 7.064 1H16.436C20.063 1 22.5 3.546 22.5 7.335V16.165C22.5 19.954 20.063 22.5 16.436 22.5Z" fill="white" fillOpacity="0.9"/>
                            </g>
                        </svg> ***/}

                    </div>
                    <img className="divider" style={{width: '100%'}}
                        src="https://cdn.animaapp.com/projects/64cdaabdc3058159a539613b/releases/65a17aae9aa2a391615fe641/img/divider.svg"
                    ></img>
                </div>

                <div className="col-12 col-sm-12 col-md-9 col-lg-10 col-xl-10 h-100 overflow-y-hidden">
                    <div className="row h-chat">
                    {messages.length == 0 && <IntroBoxComponent />}
                    { messages.map(
                            (message, index) => {
                                if (message.role === MESSAGE_ROLE_TYPE.ASSISTANT) {
                                    return <AssistantMessageBubble key={index} message={message.message} />
                                } else if (message.role === MESSAGE_ROLE_TYPE.SYSTEM) {
                                        return <SystemMessageBubble key={index} message={message.message} />
                                } else if (message.role === MESSAGE_ROLE_TYPE.ERROR) {
                                        return <ErrorMessageBubble key={index} message={message.message} />
                                } else {
                                    return <UserMessageBubble key={index} message={message.message} />
                                }
                            }
                    )}                            
                    
                    <div style={{marginBottom: '50px',}} ref={scrollToDivRef}></div>
                    {PromptBox2()}
                    </div> 
                </div>
                
            </div>
        </>
    )
}



export default MobileChatPage;