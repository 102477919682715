import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown'
import { ROLE_NAMES } from '../utils/enums';

const messageContainerStyle = {
    color: 'rgba(239, 104, 32, 1)',
    borderColor: 'red',
    borderBottom: '3px',
    width: '100%',
    /*** backgroundColor: '#FFFFFF', **/
}

const innerMessageContainerStyle = {
    paddingBottom: '0.5rem',
    paddingTop: '0.5rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    marginLeft: '23%',
    marginRight: '23%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '8px',
}

const logoContainerStyle = {
    
    maxWidth: '30px',
    maxHeight: '30px',
}

const logoStyle = {
    width: '25px',
    height: '25px',
    /*** backgroundColor: 'rgba(247,247,248,1)', ***/
    marginTop: '0px',
    borderRadius: '40px',
}

const assistantMessageStyle = {
}

const userMessageStyle = {
    /** backgroundColor: 'rgba(247,247,248,1)', ***/
}

const systemMessageStyle = {
    /*** backgroundColor: 'rgba(210,244,211,1)', ***/
}

const errorMessageStyle = {
    /*** backgroundColor: '#FF7276', ***/
}
/***
 * 
 * <img src={iconPath} alt="logo" style={logoStyle} />
 */

const MessageBubble = ({message, textStyle, containerStyle, iconPath, username, isMarkdown}) => {
    
    return (
        <div style={containerStyle}>
            <div style={innerMessageContainerStyle}>
                <div style={logoContainerStyle}>
                    {iconPath}

                </div>

                <div style={textStyle}>
                    {username && <span style={{fontWeight: 'bold'}}>{username} </span>}
                    <br />
                    {!isMarkdown && <Markdown>{message}</Markdown>}      
                    {isMarkdown && <span dangerouslySetInnerHTML={{__html: message}}></span>}                     
                </div>                
            </div>
        </div>   
    )
}

const AssistantIcon = () => {
    return (<>
            <svg width="30" height="31" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20.9412" r="20" fill="#EF6820"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M23.2428 13.7376C23.2428 15.9586 25.0459 17.759 27.2702 17.759C27.5151 17.7578 27.7594 17.7343 28 17.6888V25.6615C28 29.0156 26.0215 31 22.6624 31H15.3464C11.9785 31 10 29.0156 10 25.6615V18.3561C10 15.002 11.9785 13 15.3464 13H23.3131C23.2659 13.243 23.2423 13.49 23.2428 13.7376ZM21.15 23.8966L24.0078 20.2088V20.1912C24.2525 19.8625 24.1901 19.3989 23.8671 19.1463C23.7108 19.0257 23.5122 18.9735 23.3167 19.0016C23.1211 19.0297 22.9453 19.1358 22.8295 19.2956L20.4201 22.3951L17.6766 20.2351C17.52 20.1131 17.3207 20.0592 17.1238 20.0856C16.9269 20.1121 16.749 20.2166 16.6302 20.3756L13.6756 24.1863C13.5718 24.3158 13.5159 24.4771 13.5173 24.6429C13.5002 24.9781 13.7119 25.2826 14.0324 25.3838C14.3529 25.485 14.7014 25.3573 14.8803 25.0732L17.3512 21.8771L20.0948 24.0283C20.2508 24.1541 20.4514 24.2111 20.6504 24.1863C20.8494 24.1615 21.0297 24.0569 21.15 23.8966Z" fill="#191818"/>
                <circle opacity="0.6" cx="27.5" cy="13.5" r="2.5" fill="#191818"/>
            </svg>
        </>
    )
}

const UserIcon = () => {
    return (<>
                <svg width="30" height="31" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20" cy="20.9412" r="20" fill="#EF6820"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M25.294 16.2911C25.294 19.2281 22.9391 21.5831 20 21.5831C17.0619 21.5831 14.706 19.2281 14.706 16.2911C14.706 13.354 17.0619 11 20 11C22.9391 11 25.294 13.354 25.294 16.2911ZM20 31C15.6624 31 12 30.295 12 27.575C12 24.8539 15.6854 24.1739 20 24.1739C24.3386 24.1739 28 24.8789 28 27.599C28 30.32 24.3146 31 20 31Z" fill="#191818"/>
                </svg>
        </>
    )
}

export const AssistantMessageBubble = ({message}) => {    
    const iconPath = 'images/search_plus_gpt.png'
    return (
        <MessageBubble
            message={message}
            textStyle={assistantMessageStyle}
            containerStyle={messageContainerStyle}
            iconPath={<AssistantIcon />}
            username={ROLE_NAMES.ASSISTANT}
        />
    )
}

export const UserMessageBubble = ({message}) => {
    const iconPath = 'images/me.png'
    return (
        <MessageBubble
            message={message}
            textStyle={userMessageStyle}
            containerStyle={{...messageContainerStyle, ...userMessageStyle}}
            iconPath={<UserIcon />}
            username={ROLE_NAMES.USER}
        />
    )
}


export const SystemMessageBubble = ({message}) => { 
    const iconPath = 'images/search_plus_gpt.png'
    return (
        <MessageBubble
            message={message}
            textStyle={systemMessageStyle}
            containerStyle={systemMessageStyle}
            iconPath={<AssistantIcon />}
            username={ROLE_NAMES.SYSTEM}
        />
    )
}

export const ErrorMessageBubble = ({message}) => {
    const iconPath = 'images/search_plus_gpt.png'
    return (
        <MessageBubble
            message={message}
            textStyle={errorMessageStyle}
            containerStyle={errorMessageStyle}
            iconPath={<AssistantIcon />}
            username={ROLE_NAMES.ERROR}
        />
    )
}